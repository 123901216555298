html {
  background-color: #fff !important;;
}

body {
  background-color: #fff !important;;
  margin: 0;
  font-family: Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-welspun-secondary
{
  background-color: #e5e5e5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
