.bullet::before
{display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;

}

.float-row:hover
{   
    /* border-bottom: 1px solid #00b5f0; */
    cursor:pointer;
}

.setwidth
{
    width:350px;
    left: -12rem !important;

}

.icon-color-size{
    color:#00b5f0;
    font-size:25px
}

.underline
{
    border-bottom: 1px #00b5f0 solid;
    padding-bottom: 10px;
}
.set-border
{
    border-top:5px #00b5f0 solid !important;
}