.active{
    /* background:#f0f7f8 !important;
    color: #00b5f0  !important; */
    background:#969696 !important;
    color: #fff  !important;

}

.bg-primary-gradient
{
    /* background-image: linear-gradient(to right, #07aa97 -25%, #199385 86%,
    #17696c 236%) */
    /* background-color: #00b5f0; */
    background-color: #706F6F;

}

.sidebar-nav
{
    font-size: 22px !important;
    /* border-bottom: 1px solid white; */
    font-weight: bold;
    padding-top:10px;
}

.navbar-light .navbar-nav .nav-link{
    color:white;
}
/* .navbar-light .navbar-nav .nav-link:hover{
    color:#065F7C !important; 
} */

.brand-image{
    max-height: 402px;
    line-height:10rem;
    margin: -1rem -1.5rem -2rem;
}

.nav-bar-custom{
    margin-top:-2rem;
}

#sidenav-main .nav-item{
    padding-bottom: 20px;
}

@media (max-width: 991px) {
    /* .bg-image{
       display:none;
    } */
    .nav-bar-custom{
        margin-top:0;
    }
  }