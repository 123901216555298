/* 
.item1 { grid-area: header; }
.item2 { grid-area: main; }

.grid-container-auth {
  display: grid;
  grid-template-areas:
    'header main main main main main';
  grid-gap: 5px;
  background-color: #2196F3;
  height: 100vh;
}

.grid-container-auth > div {
  background-color: rgba(255, 
} */
.btn-border-style-outline
{
  object-fit: contain;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.border-style
{
    object-fit: contain;
    border-radius: 35px;
    border: solid 1px #003e52;
    background-color: #ffffff;
    padding: 0.5rem;
}
.btn-border-style
{
    height: 55px;
  object-fit: contain;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.item1{
    width:300px;
}

.word-break{
  word-break: break-all;
}

.vw-100
{
  width:100vw;
}
.custom-banner
{
  margin-top:4%;
  margin-left:6%;
  font-size: 80px;
  width:50%;
  text-shadow: 3px 3px 7px #000;
}
.media-desc
{
  text-align: justify;
  padding: 0 20% 0 20%;
}

.media-img
{
  margin-top:2%;
  width:50%;
}


.sidebar
{
    min-width:300px;
    min-height: 100vh;
    /* height: 100%; */

}
.sidebar-logo{
  position:fixed;
  top:20px;
  left:35px;
}
.login-modal{
    /* margin-top:80%; */
    position: fixed;
    bottom: 150px;
    width:300px;
}
.section-right-top
{
    background-image: url("../assets/img/theme/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height:350px;
    position: relative;
}
.banner-text
{
    width:600px;
    position: absolute;
    top:20%;
    left:50px;
    font-size:80px;
    color: white;
    text-shadow: 2px 2px 4px black;
}

.bottom-left
{
    margin-top: 100px;
    width:50%;
}
.bottom-right{
    width:50%
}
.info-image
{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
.section-right-bottom
{
    margin-right: 0px;
    margin-left: 0px;
}
@media(max-width:991px)
{
   .login-modal
   {
     bottom:50px;
   }
}

@media(max-width:1400px)
{
    .info-image
    {
        width:100%;
    }
    .login-modal
   {
     bottom:50px;
   }
}

@media(max-width:768px)
{
    .banner-text{
        width: auto;
    }
}
@media(max-width:670px)
{
  .banner-text{
    font-size:70px;
  }
}

@media(max-width:565px)
{
    .banner-text{
        width: auto;
        font-size: 50px;
        top: 10%
    }
}